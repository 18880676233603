import axios from 'axios';
import firebase from "firebase/app";

//let history = useHistory();

axios.defaults.withCredentials = false;

const apiBaseUrl = () => {
  if (process.env.REACT_APP_API_PROXY_URL === undefined) {
    return process.env.REACT_APP_API_URL;
  }
  return process.env.REACT_APP_API_PROXY_URL;
};

const instance = axios.create({
  baseURL: apiBaseUrl(),
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: false
});

instance.interceptors.request.use(async (config) => {
  const token = await getToken();
  config.headers['Authorization'] = token;
  // TODO: validate request schema
  // config.url
  // config.method
  // config.data
  return config;
}, (error) => {
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  // TODO: validate response here
  return response;
}, function (error) {
  if (error && error.response && error.response.status === 401) {
    // TODO: log user out?
    // history.push("/logout");
  };
  return Promise.reject(error);
});

export default instance;

export const getToken = async () => {
  // firebase authentication token
  return await firebase.auth().currentUser.getIdToken(true);
};