import { SET_USER, LOG_OUT } from '../actions/actionTypes';

const defaultState = null;

const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    case LOG_OUT:
      return null;
    default: return state;
  }
};

export default userReducer;