import AmazonS3URI from 'amazon-s3-uri';
import FileDownload from 'js-file-download';

import { SET_REPORTS } from './actionTypes';
import { resetError } from './errorActions/errorActions';

import server, { getToken } from '../api/server';
import s3 from '../api/s3';

export const getReports = (currentPage, departments, sortColumn, sortDirection) => async (dispatch) => {
  try {
    const params = currentPage ? {} : {
      department: departments,
      sort_column: sortColumn,
      sort_direction: sortDirection.toUpperCase()
    };
    const response = await server.get((currentPage || '/reports'), { params });
    const data = response.data;
    dispatch({ type: SET_REPORTS, payload: data });
    dispatch(resetError());
  } catch (error) {
    console.log(error);
    dispatch(resetError(error.toString()));
  }
};

export const postReport = (report, file, onSuccess) => async (dispatch) => {
  try {
    dispatch(resetError());
    const response = await server.post('/report', {
      ...report
    });
    const data = response.data;
    // TODO: validate response
    const sendFileMethod = data.method || 'PUT';
    const signedUrl = data.url;
    const { region, bucket, key } = AmazonS3URI(signedUrl);
    // PUT file to S3
    const responseS3 = await s3[sendFileMethod.toLowerCase()](signedUrl, file, {
      headers: {
        'Content-Type': file.type
      }
    });
    // tell the API
    await server.post('/upload_complete', {
      key
    });
    // success
    onSuccess();
  } catch (error) {
    console.log(error.response);
    dispatch(resetError(error.toString()));
  }
};

export const downloadReport = (url) => async (dispatch) => {
  // Workaround to axios redirect issue
  // Ref: https://github.com/axios/axios/issues/2855
  const token = await getToken();
  let downloadUrl = null;
  // fetch the S3 download url from API
  await s3({
    method: 'get',
    url: url,
    headers: {
      'Accept': 'application/pdf', // TODO: download xls
      'Authorization': token
    },
    responseType: 'blob'
  }).then((response) => {
    // return successful
    // PDF
    FileDownload(response.data, 'generated_report.pdf');
  }).catch((error) => {
    // AWS S3 returns an error due to the axios headers
    // check that the redirect url validates as S3
    // original excel file
    if (error.request.responseURL.match(/s3.amazonaws.com/)) {
      downloadUrl = error.request.responseURL;
    } else {
      console.log(error);
      dispatch(resetError(error.toString()));
    }
  })

  // open url in new window to initiate the download
  if (downloadUrl) {
    window.open(downloadUrl, '_blank');
  }
};

//soft deleting of a report.
export const deleteReport = (reportID, onSuccess) => async (dispatch) => {
  try {
    dispatch(resetError());
    const response = await server.delete(`/report/${reportID}`);

    // success
    onSuccess();
  } catch (error) {
    console.log(error.response);
    dispatch(resetError(error.toString()));
  }
};
