import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory
} from "react-router-dom";

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { resetError } from '../../actions/errorActions/errorActions';
import { login } from '../../actions/authActions/authActions';

function Component() {
  const dispatch = useDispatch();
  const history = useHistory();

  const errorMessage = useSelector(state => state.error);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  React.useEffect(() => {
    if (errorMessage != null) {
      alert(errorMessage);
    }
  }, [errorMessage]);

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch(resetError());
    if (email.trim() === '') return;
    dispatch(login(email, password, () => {
      history.push("/");
    }));
  };

  return (
    <Container fluid className="h-100">
      <Row className="jusitfy-content-center pt-4">
        <Col sm={{ span: 6, offset: 3 }}>
          <div className="card">
            <article className="card-body">
              <h4 className="card-title mb-4 mt-1">Log In</h4>
              <Form onSubmit={onSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" value={email} onChange={(event) => {
                    setEmail(event.target.value);
                  }} />
                </Form.Group>
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" value={password} onChange={(event) => {
                    setPassword(event.target.value);
                  }} />
                </Form.Group>
                <Button variant="primary" type="submit" block>Login</Button>
              </Form>
            </article>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Component;
