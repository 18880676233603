import { combineReducers } from 'redux';

import { LOG_OUT } from '../actions/actionTypes';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import reportsReducer from './reportsReducer';

const appReducer = combineReducers({
  auth: authReducer,
  error: errorReducer,
  reports: reportsReducer
});

// clear the redux store
const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;