import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getReports } from '../../actions/reportsActions';
import { resetError } from '../../actions/errorActions/errorActions';
import Report from '../../components/Report/Report';

import departments from '../../config/departments';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { Multiselect } from 'multiselect-react-dropdown';

import styles from './Reports.module.css';

const COLUMN_KEYS = {
  title: 'title',
  department: 'department_name',
  status: 'status',
  errors: 'error_count',
  created_at: 'created_at'
};

function Component() {
  const dispatch = useDispatch();
  const errorMessage = useSelector(state => state.error);
  const [filter, setFilter] = React.useState();
  const [sortColumn, setSortColumn] = React.useState('created_at');
  const [sortDirection, setSortDirection] = React.useState('DESC');
  const reports = useSelector(state => state.reports);

  const refreshScreen = (url, deptFilter, columnName, direction) => {
    if (deptFilter != filter && deptFilter != null)
      dispatch(getReports(url, deptFilter, sortColumn, sortDirection));
    else if (columnName && direction != null)
      dispatch(getReports(url, filter, columnName, direction));
    else dispatch(getReports(url, filter, sortColumn, sortDirection));
  };

  const setFilterFetchData = (deptFilter) => {
    setFilter(deptFilter);
    setSortDirection('DESC');
    setSortColumn('created_at');
    refreshScreen(null, deptFilter, 'created_at', 'DESC');
  };

  const onClickPagination = (url) => {
    refreshScreen(url);
  };

  const onClickSort = (columnName) => {
    const direction = sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortDirection(direction);
    setSortColumn(columnName);
    refreshScreen(null, filter, columnName, direction);
  }

  const renderSortSymbol = (columnName) => {
    if (sortColumn === columnName) {
      return (sortDirection === 'ASC') ? <CaretUpFill /> : <CaretDownFill />;
    }
    return " ";
  };

  React.useEffect(() => {
    if (errorMessage != null) {
      alert(errorMessage);
      dispatch(resetError());
    }
  }, [dispatch, errorMessage]);

  React.useEffect(() => {
    refreshScreen();
  }, []);

  return (
    <div>
      <Row style={{ paddingBottom: 20, margin: 'auto' }}>
        <Col sm={{ span: 6.5, offset: 0 }}>
          <Multiselect
            isObject={false}
            options={departments}
            onSelect={setFilterFetchData}
            onRemove={setFilterFetchData}
            displayValue="key"
            showCheckbox={true}
            placeholder="Filter by Department"
            closeOnSelect={true}
            avoidHighlightFirstOption={true}
            hidePlaceholder={false}
            closeIcon="cancel"
            style={
              {
                inputField: {
                  margin: 0
                },
                chips: {
                  background: "#007bff",
                  borderRadius: 4,
                },
                optionContainer: {
                  background: "white"
                }
              }
            }
          />
        </Col>
      </Row>
      {
        reports.reports.length > 0 ?
          <Row style={{ margin: 'auto' }} >
            <Table striped bordered hover size="sm" style={styles}>
              <thead>
                <tr>
                  <th onClick={() => onClickSort(COLUMN_KEYS.title)}>
                    Report Name {renderSortSymbol(COLUMN_KEYS.title)}
                  </th>
                  <th onClick={() => onClickSort(COLUMN_KEYS.department)}>
                    Department {renderSortSymbol(COLUMN_KEYS.department)}
                  </th>
                  <th style={{ cursor: 'auto' }}> Status</th>
                  <th style={{ cursor: 'auto' }}> Error </th>
                  <th onClick={() => onClickSort(COLUMN_KEYS.created_at)}>
                    Timestamp {renderSortSymbol(COLUMN_KEYS.created_at)}
                  </th>
                  <th style={{ cursor: 'auto' }}> Download </th>
                </tr>
              </thead>
              <tbody>
                {
                  reports.reports.map((report, index) => {
                    return (
                      <Report
                        key={`${index}_${report.title}`}
                        report={report}
                        onDelete={() => {
                          refreshScreen();
                        }}
                      />
                    );
                  })
                }
              </tbody>
            </Table>
          </Row>
          : <div>No reports found.</div>
      }
      <Pagination>
        <Pagination.First
          onClick={() => { onClickPagination(reports.links.first) }}
          disabled={!reports.links.first} />
        <Pagination.Prev
          onClick={() => { onClickPagination(reports.links.prev) }}
          disabled={!reports.links.prev} />
        <Pagination.Next
          onClick={() => { onClickPagination(reports.links.next) }}
          disabled={!reports.links.next} />
        <Pagination.Last
          onClick={() => { onClickPagination(reports.links.last) }}
          disabled={!reports.links.last} />
      </Pagination>
    </div>
  );
};

export default Component;
