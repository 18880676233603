import { SET_REPORTS } from '../actions/actionTypes';

const defaultState = {
  "reports": [],
  "links": {
    "next": "TODO",
    "prev": "TODO",
    "current_page": "TODO",
    "first": "TODO",
    "last": "TODO"
  }
};

const reportsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_REPORTS:
      return action.payload;
    default: return state;
  }
};

export default reportsReducer;