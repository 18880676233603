import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NoMatch from '../screens/404/404';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Authenticated from './Authenticated/Authenticated';

import LoginPage from '../screens/Login/Login';
import LogoutPage from '../screens/Logout/Logout';

function Component() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/logout">
            <LogoutPage />
          </Route>
          <PrivateRoute path="/">
            <Authenticated />
          </PrivateRoute>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default Component;
