import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Redirect
} from 'react-router-dom';

import { logout } from '../../actions/authActions/authActions';

function Component() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(logout());
  });

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {}
      }}
    />
  );
}

export default Component;