import firebase from 'firebase/app';

import { SET_USER, LOG_OUT } from '../actionTypes';
import { resetError } from '../errorActions/errorActions';

const setUser = (user) => {
  localStorage.setItem('user', user);
  return { type: SET_USER, payload: user };
};

const clearStorage = async () => {
  localStorage.clear();
};

export const logout = (callback) => async (dispatch) => {
  try {
    await clearStorage();
    await firebase.auth().signOut();
  } catch (err) {
    console.log(err);
  } finally {
    dispatch({ type: LOG_OUT });
    if (callback) callback();
  }
};

export const login = (email, password, onSuccess) => async (dispatch) => {
  try {
    dispatch(resetError());
    await clearStorage();

    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(function () {
        return firebase.auth().signInWithEmailAndPassword(email, password);
      })
      .then((data) => {
        const token = firebase.auth().currentUser.getIdToken(true);
        // TODO: refactor setUser
        dispatch(setUser(token));
        onSuccess();
      })
      .catch(function (error) {
        const errorMessage = error.message;
        dispatch(resetError(errorMessage));
      });
  } catch (error) {
    console.log(error.response);
    dispatch(resetError(error.toString()));
  }
};

export const listenAuthStateChange = () => async (dispatch) => {
  firebase.auth().onAuthStateChanged(async (authUser) => {
    if (!authUser) {
      // auto log out
      dispatch(logout());
    }
  });
};
