import * as React from 'react';
import {
  Link
} from "react-router-dom";

import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

function Component({ navigation }) {

  return (
    <Navbar variant="dark" bg="dark" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="#home">Better Purchasing</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/" className="nav-link">Reports</Link>
          </Nav>
          <Button variant="outline-light" href="/logout">Logout</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Component;
