import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from '../reducers/index';

const logger = createLogger();
let middleware = [];
if (process.env.NODE_ENV === 'production') {
  middleware = [...middleware, thunk];
} else {
  middleware = [...middleware, thunk, logger];
};

export default createStore(rootReducer, applyMiddleware(...middleware));