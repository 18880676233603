import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal'

import { resetError } from '../../actions/errorActions/errorActions';

function Component({ show, onHide, onDownload, report }) {

  const [reportThresholdUnder, setReportThresholdUnder] =
    React.useState(report.exceptions_threshold_under);
  const [reportThresholdOver, setReportThresholdOver] =
    React.useState(report.exceptions_threshold_over);
  const [sortBySelection, setSortBySelection] =
    React.useState(false);

  const dispatch = useDispatch();

  const errorMessage = useSelector(state => state.error);

  React.useEffect(() => {
    if (errorMessage != null) {
      alert(errorMessage);
      dispatch(resetError());
    }
  }, [dispatch, errorMessage]);

  const isReportPercentValid = (label) => {
    return label >= 0 && label <= 100 && label !== '';
  }

  const onClickDownload = () => {
    if (
      !isReportPercentValid(reportThresholdUnder) ||
      !isReportPercentValid(reportThresholdOver)
    ) {
      return alert('Please enter a valid threshold percent.');
    };
    onDownload(reportThresholdUnder, reportThresholdOver, sortBySelection);
    onHide();
  };

  const renderFormControlPercentage = (label, defaultValue, action) => {
    return (
      <Form.Row style={{
        paddingTop: 10,
        paddingBottom: 10
      }}>
        <Col xs={6}>
          <Form.Label>{label}</Form.Label>
        </Col>
        <Col>
          <Form.Control
            type="number"
            min={0}
            max={100}
            defaultValue={defaultValue}
            placeholder="Enter a percentage" onChange={(event) => {
              action(event.target.value);
            }}
          />
        </Col>
      </Form.Row>
    )
  }

  const renderSortingOptions = () => {
    const formSortCheck = (label, value, defaultValue) => {
      return (
        <Form.Check
          style={{paddingBottom: 10}}
          type="radio"
          name="sortByGroup"
          label={label}
          value={value}
          defaultChecked={defaultValue}
          onChange={(event) => {
            setSortBySelection(event.target.value)}}
        >
        </Form.Check>
      )
    }
    return (
      <Form.Row>
        <Col xs={6}>
          <Form.Label>Exceptions Sorting Options</Form.Label>
        </Col>
        <Col>
          <Form.Group>
            {formSortCheck('Sort by product number', false, true)}
            {formSortCheck('Sort by best bin column', true, false)}
          </Form.Group>
        </Col>
      </Form.Row>
    )
  }

  const renderFooterActionButtons = () => {
    return (
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>Close</Button>
        <Button variant="primary" onClick={onClickDownload}>Download</Button>
      </Modal.Footer>
    )
  }

  return (
    <Modal show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Exception Report Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderFormControlPercentage(
          'Exceptions Threshold Under',
          reportThresholdUnder,
          setReportThresholdUnder
        )}
        {renderFormControlPercentage(
          'Exceptions Threshold Over',
          reportThresholdOver,
          setReportThresholdOver
        )}
        {renderSortingOptions()}
      </Modal.Body>
      {renderFooterActionButtons()}
    </Modal>
  );
}
export default Component;
