import React from 'react';
import { Provider } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  FirebaseAuthProvider
} from '@react-firebase/auth';
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";

import { config } from './config/firebaseConfig';

import Routes from './routes/Routes';
import store from './store';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'production',
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE || 1.0
  });
};

function App() {

  React.useEffect(() => {
    document.title = 'Canadian Tire | Better Purchasing';
  }, []);

  return (
    <FirebaseAuthProvider firebase={firebase} {...config}>
      <Provider store={store}>
        <Routes></Routes>
      </Provider>
    </FirebaseAuthProvider>
  );
}

export default App;
