import * as React from 'react';
import { useDispatch } from 'react-redux';
import Moment from 'react-moment';

import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

import { downloadReport, deleteReport } from '../../actions/reportsActions';

import ExceptionPrompt from '../../components/ExceptionPrompt/ExceptionPrompt';

function Component({ report, onDelete }) {

  const [showExceptionPrompt, setShowExceptionPrompt] = React.useState(false);
  const dispatch = useDispatch();

  const onClickDownload = (url) => {
    dispatch(downloadReport(url));
  };

  const onClickDelete = (reportD) => {
    const confirmD = window.confirm("🛑 Are you sure you want to delete the Report?");
    if(confirmD == true) {
      dispatch(deleteReport(reportD, onDelete))
    }
  };

  return (
    <>
      <tr>
        <td>
          {report.title}
        </td>
        <td>
          {report.department_name}
        </td>
        <td>
          <Badge variant="secondary">{report.status}</Badge>
        </td>
        <td title={report.errors.toString()}>
          {report.errors.length}
        </td>
        <td>
          <Moment format="h:mma YYYY-MM-DD">{report.created_at}</Moment>
        </td>
        <td>
          <Button
            variant="primary"
            disabled={!report.download_link || report.status === 'processing'}
            size='sm'
            onClick={() => {
              onClickDownload(report.download_link);
            }}
          >
            Report
          </Button>{' '}
          <Button
            variant="secondary"
            disabled={!report.download_link_original || report.status === 'processing'}
            size='sm'
            onClick={() => {
              onClickDownload(report.download_link_original);
            }}
          >
            Original
          </Button>{' '}
          <Button
            variant="secondary"
            disabled={!report.download_link_original || report.status === 'processing'}
            size='sm'
            onClick={() => setShowExceptionPrompt(true)}
          >
            Exceptions
          </Button>{' '}
          <Button
            variant="danger"
            disabled={!report.download_link_original || report.status === 'processing'}
            size='sm'
            onClick={() => {
              onClickDelete(report.id);
            }}
          >
            Delete
          </Button>
        </td>
      </tr>
      <ExceptionPrompt
        show={showExceptionPrompt}
        onHide={() => setShowExceptionPrompt(false)}
        onDownload={(
          exceptions_threshold_under,
          exceptions_threshold_over,
          exceptions_sort_by_selection
        ) => {
          console.debug(exceptions_threshold_under + ' ' + exceptions_threshold_over)
          const url = report.download_link_exceptions +
            `?exceptions_threshold_under=${exceptions_threshold_under}` +
            `&exceptions_threshold_over=${exceptions_threshold_over}` +
            `&exceptions_sort_by_selection=${exceptions_sort_by_selection}`
          dispatch(downloadReport(url));
        }}
        report={{
          exceptions_threshold_under: report.exceptions_threshold_under,
          exceptions_threshold_over: report.exceptions_threshold_over,
          url: report.download_link
        }} />
    </>
  );
};

export default Component;
