import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import NavBar from '../../components/NavBar/NavBar';
import Home from '../../screens/Home/Home';
import Report from '../../screens/Report/Report';

function Component() {
  return (
    <Router>
      <div>
        <NavBar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/report">
            <Report />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};

export default Component;