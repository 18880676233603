import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useHistory
} from "react-router-dom";
import Dropzone from 'react-dropzone';

import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup'

import departments from '../../config/departments';
import { postReport } from '../../actions/reportsActions';
import { resetError } from '../../actions/errorActions/errorActions';
import styles from './Report.module.css';

function Component({ navigation }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const errorMessage = useSelector(state => state.error);
  const dragDropLabelInitial = 'Choose a file or drag it here.';
  const [loading, setLoading] = React.useState(false);
  const [dragDropLabel, setDragDropLabel] = React.useState(dragDropLabelInitial);
  const [reportTitle, setReportTitle] = React.useState();
  const [reportDepartment, setReportDepartment] = React.useState(departments[0]);
  const [reportFile, setReportFile] = React.useState();
  const [reportThresholdUnder, setReportThresholdUnder] = React.useState(20);
  const [reportThresholdOver, setReportThresholdOver] = React.useState(20);
  const [showExceptionPrompt, setShowExceptionPrompt] = React.useState(false);

  React.useEffect(() => {
    if (errorMessage != null) {
      alert(errorMessage);
      dispatch(resetError());
      setLoading(false);
    }
  }, [errorMessage]);

  const isExcelFile = (fileName) => {
    return /\.(xlsx)$/i.test(fileName);
  };

  const isReportTextValid = (label) => {
    return (label || '').trim() !== '' && (label || '').length > 3;
  };

  const isReportPercentValid = (label) => {
    return label >= 0 && label <= 100 && label !== '';
  }

  const onSubmit = () => {
    // validation
    if (!isReportTextValid(reportTitle)) {
      return alert('Please enter a valid report title.');
    };
    if (!isReportTextValid(reportDepartment)) {
      return alert('Please select a department.');
    };
    if (!isReportPercentValid(reportThresholdUnder)) {
      return alert('Please enter a valid threshold under percent');
    };
    if (!isReportPercentValid(reportThresholdOver)) {
      return alert('Please enter a valid threshold over percent.');
    };
    if (!reportFile) {
      return alert('Please attach an excel file.');
    };
    setLoading(true);
    dispatch(postReport({
      title: reportTitle,
      department_name: reportDepartment,
      content_type: reportFile.type,
      filename: reportFile.name,
      exceptions_threshold_under: parseFloat(reportThresholdUnder),
      exceptions_threshold_over: parseFloat(reportThresholdOver)
    }, reportFile, () => {
      // success
      alert('Report is successfully processing...');
      history.push('/');
    }));
  };

  const onDrop = (files) => {
    // validation
    if (files.length > 1) {
      return alert('Please drop only 1 file.');
    };
    if (isExcelFile(files[0].name) === false) {
      return alert('Please drop a valid .xlsx file.');
    };
    setDragDropLabel(files[0].name);
    setReportFile(files[0]);
  };

  return (
    <Container>
      <div className="App">
        <Row>
          <Col>
            <h3>New Report</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 6, offset: 0 }}>
            <Form.Group>
              <Form.Label>Report Title</Form.Label>
              <Form.Control type="reportTitle" placeholder="Enter report title" onChange={(event) => {
                setReportTitle(event.target.value);
              }} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Department</Form.Label>
              <Form.Control as="select" onChange={(event) => {
                setReportDepartment(event.target.value);
              }}>
                {
                  departments.map((department, index) => {
                    return (
                      <option key={index}>{department}</option>
                    );
                  })
                }
              </Form.Control>
            </Form.Group>

            <Form.Row>
              <Col xs={5}>
                <Form.Group>
                  <Form.Label>Exceptions Threshold Under</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      defaultValue={reportThresholdUnder}
                      placeholder="Enter a percentage" onChange={(event) => {
                        setReportThresholdUnder(event.target.value);
                      }} />
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs={5}>
                <Form.Group>
                  <Form.Label>Exceptions Threshold Over</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      max={100}
                      defaultValue={reportThresholdOver}
                      placeholder="Enter a percentage" onChange={(event) => {
                        setReportThresholdOver(event.target.value);
                      }} />
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Form.Row>
          </Col>
          <Col sm={{ span: 6, offset: 0 }}>
            <div className={styles.dragDropWrapper}>
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                      <p>{dragDropLabel}</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={{ paddingTop: 20 }}>
            <Button
              variant="success"
              disabled={!isReportTextValid(reportTitle) || !isReportTextValid(reportDepartment) || !reportFile || loading}
              onClick={onSubmit}>
              {loading ? 'Uploading...' : 'Create Purchasing Report'}
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              variant="secondary"
              onClick={() => {
                history.push('/');
              }}>
              Cancel
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Component;
