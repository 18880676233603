import * as React from 'react';
import {
  useHistory
} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Reports from '../../components/Reports/Reports';

function Component({ navigation }) {
  const history = useHistory();

  return (
    <Container>
      <Row className="App">
        <Col>
          <h3>Purchasing Reports</h3>
        </Col>
        <Col>
          <Button
            variant="success"
            onClick={() => {
              history.push("/report");
            }}
            className='float-right'
          >
            New Report
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Reports />
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
