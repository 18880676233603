import React from 'react';
import {
  Route,
  Redirect,
} from "react-router-dom";
import firebase from 'firebase/app';
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import { useDispatch } from 'react-redux';

import { listenAuthStateChange } from '../../actions/authActions/authActions';

function PrivateRoute({ children, ...rest }) {
  const dispatch = useDispatch();

  const [loadingAuth, setLoadingAuth] = React.useState(true);

  // initialize a listener to auto log out user if token expires
  React.useEffect(() => {
    dispatch(listenAuthStateChange());
  }, []);

  // wait until firebase is initialized to load app
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setLoadingAuth(false);
    });
  }, []);

  if (loadingAuth) {
    return (
      <div>Loading...</div>
    );
  };

  return (
    <Route {...rest} render={({ location }) =>
      <FirebaseAuthConsumer>
        {({ isSignedIn, user, providerId }) => {
          if (isSignedIn) {
            return (
              <>
                {children}
              </>
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          );
        }}
      </FirebaseAuthConsumer>
    }
    />
  );
};

export default PrivateRoute;